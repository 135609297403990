import React, {useEffect, useState} from 'react';
import defaultImg from "../../../assets/store/default-store-img.jpg"
import ActionNav from './ActionNav';
import CategorySection from './CategorySection';
import StoreCard from './StoreCard';
import { Upload } from 'lucide-react';


import "./merchant-store.css";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMerchantStoreQuery } from '../../../Actions/BulkUpload/BulkUpload';
import { logout } from '../../../Actions/Auth/authSlice';
import Loader from '../../../utils/Loader';

const MerchantStore = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [activeItem, setActiveItem] = useState("All Products");
     const [limit, setLimit] = useState(12)
     const {token, user_type, _id} = useSelector(state => state.auth);
     const {data, isError, error, isLoading, isFetching} = useGetMerchantStoreQuery(_id);
     const storeName = data?.results?.name
     const storeDescription = data?.results?.description
     const storeImage = data?.results?.image_url
     const storeLink = data?.results?.store_link
     const products = data?.results?.products
  // Extract error details
  const errorMessage = isError ? error?.data?.message || error?.error || '' : '';
  const errorCode = isError ? error?.data?.code || '' : '';

  const lastIndex = products && products?.length
  const currentData = products && products.slice(0,limit);
  const currentLength = currentData && currentData?.length;


 const filteredProduct =  products && products.filter((product) => {
      return product.productName.toLowerCase().includes(activeItem.toLowerCase()) || product.categoryName.toLowerCase().includes(activeItem.toLowerCase())
 });

 const dataToDisplay = activeItem !== "All Products" ? filteredProduct : activeItem === "All Products" ? currentData : filteredProduct;
  useEffect(() => {
    // Check for specific error conditions and handle them
    if (
      (errorMessage === 'Invalid token' && errorCode === 401) ||
      (errorMessage === 'Access denied. No token provided' && errorCode === 403)
    ) {
      navigate('/login');
      dispatch(logout());
    }else  if(token && user_type !== "class"){
        navigate("/")
    }
  }, [errorMessage, errorCode, navigate, dispatch, token, user_type]);

     useEffect(() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }, []);

  return (
    <section className='store-container'>
        <div className='store-header'>
             <div className='header-content'>
                  <img src={storeImage? storeImage : defaultImg} className='store-image' alt="store"/>
                  <div className='store-title'>
                       <h1>{storeName}</h1>
                       <p>{storeDescription}</p>
                  </div>
             </div>
        </div>
        <div className='store-content'>
             <ActionNav storeLink={storeLink}/>
             <CategorySection activeItem={activeItem} setActiveItem={setActiveItem} setLimit={setLimit}/>
             {isLoading ? <Loader/> : <StoreCard data={dataToDisplay} setLimit={setLimit} lastIndex={lastIndex} currentLength={currentLength} isFetching={isFetching}/>}
        </div>
        <Link to="/merchant/store/upload" className='upload-btn' >
           <Upload size={18}/>
           <span>upload</span>
        </Link>
    </section>
  
  )
}

export default MerchantStore