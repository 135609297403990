import React, {createContext, useState, useRef, useMemo, useCallback, useReducer} from 'react';
import { patterns, validateForDestopDoorDelivery, validateForDestopPickup, ValidateForMobileDoorDelivery, ValidateForMobilePickup } from '../utils/Validations';
import { actionTypes } from './constants';
import { getUrl } from '../helpers/getUrl';
import { useLocalStorage } from '../utils/useLocalStorage';

export const AppContext = createContext(null);


const AppContextProvider = ({children}) => {
    const {setItem, getItem} = useLocalStorage("batch_upload_items");
    const [editIndex, setEditIndex] = useState(null)
    const [mobileCheckoutModal, setMobileCheckoutModal] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [currentSizeIndex, setCurrentSizeIndex] = useState(null);
    const [resetPasswordOtp, setResetPasswordOtp] = useState("");
    const [discountError, setDiscountError] = useState("");
    const [priceValue, setPriceValue] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [currentUrl, setCurrentUrl] = useState("/")
    const [copySuccess, setCopySuccess] = useState('');
    const [ itemLimit, setItemLimit] = useState(8);
    const [pageNumber, setPageNumber] = useState(1)
    const [categoryName, setcategoryName] = useState("");
    const [pickupAddressError, setPickupAddressError] = useState("")
    const [copied, setCopied] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [socialMediaShareOpen,setSocialMediaShareOpen] = useState(false);
    const [openDownload, setOpenDownload] = useState(false);
    const [saveProductErrors, setSaveProductErrors] = useState({})
    const [uploadSuccessData, setUploadSuccessData] = useState([])
    const [page,setPage] = useState(1);
    const [discountChecked, setDiscountCheck] = useState(false)
    const about = useRef();
    const faq = useRef();
    const price = useRef();
    const phoneDoorDeliveryRef = useRef(null);
    const emailDoorDeliveryRef = useRef(null);
    const phonePickupRef = useRef(null);
    const emailPickupRef = useRef(null);
    const mobileeDoorDeliveryPhoneRef = useRef(null);
    const mobileeDoorDeliveryEmailRef = useRef(null);
    const mobilePickupPhoneRef = useRef(null);
    const mobilePickupEmailRef = useRef(null);   
    const mobileDeliveryMethodRef = useRef(null);
    const errorRef = useRef(null);
    const discountErrorRef = useRef(null);
    const pickupErrorRef = useRef(null)

    
  const INITIAL_STATE = {
    tag: "",
    vehicleType: "",
    address: "",
    productName: "",
    color: "",
    front_view: "",
    description: "",
    rear_view: "",
    left_view: "",
    right_view: "",
    video_url: "",
    category: "",
    sub_category: "",
    sub_category_sizes: [],
    price: 0,
    quantity: 0,
    customer_code: getUrl("ACC001164", "IND265936"),
    promo_name: "",
    discounted_price: 0,
    lat: "",
    long: "",
    sizes: [],
    gender: "",
    productImage: "",
    userId: "",
    gift_wrapping: false,
    merchant_first_name: "",
    merchant_last_name: "",
    merchant_phone_number: "",
    merchant_email: "",
    state: "",
    neighborhood: "",
    locality: "",
    lga: "",
    merchant_station_id: null,
    weight: "",
    get_category: {},
    batch_upload_items: getItem(),
    success_data: [],
    prefilledData: {}
  };

  const resetState = {
    tag: "",
    vehicleType: "",
    address: "",
    productName: "",
    color: "",
    front_view: "",
    description: "",
    rear_view: "",
    left_view: "",
    right_view: "",
    video_url: "",
    category: "",
    sub_category: "",
    sub_category_sizes: [],
    price: 0,
    quantity: 0,
    customer_code: getUrl("ACC001164", "IND265936"),
    promo_name: "",
    discounted_price: 0,
    lat: "",
    long: "",
    sizes: [],
    gender: "",
    productImage: "",
    gift_wrapping: false,
    state: "",
    neighborhood: "",
    locality: "",
    lga: "",
    merchant_station_id: null,
    weight: "",
    get_category: {},
    success_data: [],
    prefilledData: {}
  }

 const productUploadReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY:
      return {
        ...state,
        get_category: action.payload
      };
    case actionTypes.UPDATE_SIZES:
      return {
        ...state,
        sizes: action.payload
      };
    case actionTypes.CATEGORY_ID:
      return {
           ...state,
           category: action.payload

      }
    case actionTypes.SUB_CATEGORY_SIZES:
      return {
          ...state,
          sub_category_sizes: action.payload
      }
    case actionTypes.CHANGE_INPUT:
      return {
          ...state,
          [action.payload.name] : action.payload.value 
      }
    case actionTypes.UPDATE_GENDER:
      return {
        ...state,
        gender: action.payload 
      };
      case actionTypes.UPDATE_COLOR: {
        const updatedColors = new Set(state.color.split(',').filter(Boolean));
  
        if (updatedColors.has(action.payload)) {
          updatedColors.delete(action.payload);
        } else {
          updatedColors.add(action.payload);
        }
  
        return {
          ...state,
          color: Array.from(updatedColors).join(',')
        };
    }
    case actionTypes.SET_MERCHANT_EMAIL:
      return { ...state, merchant_email: action.payload };
    case actionTypes.SET_MERCHANT_FIRSTNAME:
      return { ...state, merchant_first_name: action.payload };
    case actionTypes.SET_MERCHANT_LASTNAME:
      return { ...state, merchant_last_name: action.payload };
    case actionTypes.SET_MERCHANT_PHONE:
      return { ...state, merchant_phone_number: action.payload };
    case actionTypes.SET_USERID:
      return { ...state, userId: action.payload };
    case actionTypes.UPDATE_QUANTITY: 
    {
      return { ...state, quantity: action.payload };
    }
    case actionTypes.RESTART_CATEGORY_SELECTION: 
        return {
              ...state,
              sub_category: "",
              sub_category_sizes: [],
              sizes: [],
              gender: "",
              color: "",
    
    }
    case actionTypes.SET_VEHICLE_TYPE:
        return {...state, vehicleType: action.payload}
    case actionTypes.SET_ADDRESS:
        return { ...state, address: action.payload };
    case actionTypes.SET_MERCHANT_STATION_ID:
        return {...state, merchant_station_id: action.payload}
    case actionTypes.SET_COORDINATES:
        return { ...state, lat: action.payload.lat, long: action.payload.lng };
    case actionTypes.SET_STATE:
        return { ...state, state: action.payload };
    case actionTypes.SET_LOCALITY:
        return { ...state, locality: action.payload };
    case actionTypes.SET_NEIGHBORHOOD:
        return { ...state, neighborhood: action.payload};
    case actionTypes.SET_LGA:
        return {...state, lga: action.payload};
    case actionTypes.SET_LOCATION_ERROR: 
        return { ...state, error: action.payload };
    case actionTypes.SET_LAT:
        return { ...state, lat: action.payload };
    case actionTypes.SET_LONG:
          return { ...state, long: action.payload };
    case actionTypes.SET_FRONTVIEW_IMAGE:
          return {...state, front_view: action.payload};
    case actionTypes.SET_LEFTVIEW_IMAGE:
          return {...state, left_view: action.payload};
    case actionTypes.SET_RIGHTVIEW_IMAGE: 
          return {...state, right_view: action.payload};
    case actionTypes.SET_REARVIEW_IMAGE:
          return {...state, rear_view: action.payload};
    case actionTypes.SET_VIDEO:
          return {...state, video_url: action.payload};
    case actionTypes.SET_SUCCESS_DATA:
          return {...state, success_data: action.payload};
    case actionTypes.ADD_PRODUCT:
        const addedItems = [...state?.batch_upload_items, action.payload];
        setItem(addedItems); // Save to localStorage
        return {...state, batch_upload_items: addedItems };  
        case actionTypes.REMOVE_PRODUCT: {
          // Filter out the item at the specified index
          const removedItems = state?.batch_upload_items?.filter((_, index) => index !== action.payload);
        
          // Update localStorage with the new array without the removed item
          setItem(removedItems);
        
          return {
            ...state,
            batch_upload_items: removedItems,
          };
        }
      
      case actionTypes.EDIT_PRODUCT:
      return {
        ...state,
        batch_upload_items: state.batch_upload_items.map((item, i) => {
          if (i === action.index) {
            return { ...item, ...action.payload };
          }
          return item;
        }),
        prefilledData: state.batch_upload_items[action.index] || {},
      };

      case actionTypes.SET_PRODUCT:
        return {
          ...state,
          batch_upload_items: action.payload,
        };
    case actionTypes.CLEAR_STATE: 
          return {...state, ...resetState};
    default:
        return state;
  }
};

const [state, uploadDispatch] = useReducer(productUploadReducer, INITIAL_STATE)

    const scrollToSection = (elementRef) => {
        window.scrollTo({
             top: elementRef.current.offsetTop,
             behavior: "smooth"
        })
  }
  const slugify = useCallback((str) => {
    return str.trim().replace(/\s+/g, "-").toLowerCase()
},[])
const removeSlug = useCallback((str) => {
  return str.trim().replace(/-+/g, " ").toLowerCase()
},[]);
const normalizeCase = useCallback((t) => {
  const words = t?.toLowerCase().split(' ');
  const keywords = new Set( ["for","and", "nor", "but", "or", "yet", "so", "if", "although", "though", "after", "before", "because", "as if", "unless", "until", "when", "while"]);
   return words?.map(w => {
      const newWord = w[0] ? (w[0].toUpperCase() + w.slice(1)) : ''
      return  keywords.has(w) ? w : newWord;
  }).join(' ');
},[])
    const numberSeperator = (num) => {
        return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const handleScroll = useCallback((ref) => {
      window.scrollTo({
        top: ref.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }, []);

  
    const handleErrorForDesktopDoorDelivery = (fieldValue, allValue) => {
        validateForDestopDoorDelivery(fieldValue, patterns[allValue])
    }
    const handleErrorForDesktopPickup = (fieldValue, allValue) => {
        validateForDestopPickup(fieldValue, patterns[allValue])
    }
    const handleErrorForMobileDoorDelivery = (fieldValue, allValue) => {
        ValidateForMobileDoorDelivery(fieldValue, patterns[allValue])
    }
    const handleErrorForMobilePickup = (fieldValue, allValue) => {
        ValidateForMobilePickup(fieldValue, patterns[allValue])
    }
    function genPassword(len) {
      const myArray = new Uint32Array(len);
      let password = "";
      window.crypto.getRandomValues(myArray);
      
      for (const num of myArray) {
        password += "alpha" + num
      }
         return password;
     }
     function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    const copyToClipBoard = async copyMe => {
        try {
            if(navigator?.clipboard?.writeText){
                await navigator.clipboard.writeText(copyMe);
            }
          setCopySuccess('Copied!');
          setCopied(true)
          setTimeout(() => {
             setCopySuccess("")
             setCopied(false)
          }, 500)
        } catch (err) {
          setCopySuccess('Failed to copy!');
          setTimeout(() => {
            setCopySuccess("");
            setCopied(false)
         }, 500)
        }
      };
      const shareOnSocialMedia = async (url, text) => {
        try {
            await navigator.share({
                title: document.title,
                url : url,
                text : text});
          
          } catch (err) {
            console.error("Share failed:", err.message);
          }
    }


    const appMemoization = useMemo(() => ({numberSeperator, mobileCheckoutModal, setMobileCheckoutModal, phoneDoorDeliveryRef, emailDoorDeliveryRef, phonePickupRef, emailPickupRef, handleErrorForDesktopDoorDelivery, handleErrorForDesktopPickup, handleErrorForMobileDoorDelivery, handleErrorForMobilePickup, mobileeDoorDeliveryPhoneRef, mobileeDoorDeliveryEmailRef, mobilePickupPhoneRef, mobilePickupEmailRef, genPassword,scrollToSection,about,faq,price, isEmpty,page,setPage,copyToClipBoard,copySuccess,copied,openShareModal, setOpenShareModal,socialMediaShareOpen,setSocialMediaShareOpen, shareOnSocialMedia,slugify,removeSlug,normalizeCase, itemLimit, setItemLimit,pageNumber, setPageNumber,openDownload, setOpenDownload,categoryName, setcategoryName, resetPasswordOtp, setResetPasswordOtp,currentUrl, setCurrentUrl,handleScroll,mobileDeliveryMethodRef, state, uploadDispatch, showColorPicker, setShowColorPicker, currentSizeIndex, setCurrentSizeIndex,saveProductErrors, setSaveProductErrors,errorRef, uploadSuccessData, setUploadSuccessData, discountError, setDiscountError,priceValue, setPriceValue,discountValue, setDiscountValue,discountErrorRef,discountChecked, setDiscountCheck,editIndex, setEditIndex, pickupAddressError, setPickupAddressError,pickupErrorRef
    }), [
      mobileCheckoutModal, setMobileCheckoutModal,copySuccess,copied,openShareModal, setOpenShareModal,socialMediaShareOpen,setSocialMediaShareOpen,page,setPage,about,faq,price,slugify,removeSlug,normalizeCase, itemLimit, setItemLimit,pageNumber, setPageNumber,openDownload, setOpenDownload,categoryName, setcategoryName, resetPasswordOtp, setResetPasswordOtp,currentUrl, setCurrentUrl,handleScroll,mobileDeliveryMethodRef,state, uploadDispatch, showColorPicker, setShowColorPicker, currentSizeIndex, setCurrentSizeIndex,saveProductErrors, setSaveProductErrors,errorRef, uploadSuccessData, setUploadSuccessData,discountError, setDiscountError,priceValue, setPriceValue,discountValue, setDiscountValue, discountErrorRef,discountChecked, setDiscountCheck,editIndex, setEditIndex,pickupAddressError, setPickupAddressError,pickupErrorRef
    ])
    const {Provider} = AppContext
  return <Provider value={appMemoization}>
      {children}
  </Provider>;
};
export default AppContextProvider