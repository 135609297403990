import React, {lazy, Suspense} from 'react';
import Error from '../helpers/Error';
import SuccessfulTransaction from '../components/Layouts/Cart/Mobile/SuccessfulTransaction';
import {Routes,Route, useLocation} from "react-router-dom";
import Loader from '../utils/Loader';
import Giglsales from '../components/Layouts/Store/GIGLSALES/GiglSales';
import GiglProductDetails from '../components/Layouts/Store/GIGLSALES/GiglProductDetails';
import ListPromotions from '../components/Layouts/Promotions/ListPromotions';
import DeleteAccount from '../components/Layouts/DeleteAccount/DeleteAccount';
import SearchCatalog from '../components/Layouts/Home/Search/SearchCatalog';
import Upload from '../components/Layouts/Merchant/Upload/Upload';
import MerchantStore from '../components/Layouts/Merchant/MerchantStore';
import BulkProducts from '../components/Layouts/Merchant/BulkProducts/BulkProducts';
import UploadSuccess from '../components/Layouts/Merchant/BulkProducts/UploadSuccess';

const App = lazy(() => import("../App"));
const ProductDetails = lazy(() => import('./../components/Layouts/ProductDetails'));
const Store = lazy(() => import("../components/Layouts/Store/Store"));
const Register = lazy(() => import("../components/Layouts/Auth/Register"));
const Login = lazy(() => import("../components/Layouts/Auth/Login"));
const ForgotPassword = lazy(() => import('./../components/Layouts/Auth/ForgotPassword')); 
const ResetPassword = lazy(() => import('./../components/Layouts/Auth/ResetPassword'));
const VerifyOtp = lazy(() => import("./../components/Layouts/Auth/Otp"))
const TrackOrder = lazy(() => import("../components/Layouts/TrackOrder/TrackOrder"));
const TrackedItem = lazy(() => import("../components/Layouts/TrackOrder/TrackedItem"));
const AppleAppSiteAssociation = lazy(() => import("../components/Layouts/apple-app-site-association/AppleAppSiteAssociation"));
const Terms = lazy(() => import("../components/Layouts/Policies/Term"));
const Policy  = lazy(() => import("../components/Layouts/Policies/Policy"));
const Refund = lazy(() => import("../components/Layouts/Refund/Refund"));
const  RefundRequested  = lazy(() => import("../components/Layouts/Refund/RefundRequested"));
const CategoryDetails = lazy(() => import("../components/Layouts/Home/Categories/CategoryDetails"));
const About = lazy(() => import('../components/Layouts/About/About'))
const Faq = lazy(() => import('../components/Layouts/FAQ/Faq'));
const Pricing = lazy(() => import("../components/Layouts/Pricing/Pricing"));
const Contact = lazy(() => import('../components/Layouts/Contact/Contact'));
const Categories = lazy(() => import("../components/Layouts/CategoriesPage/Categories"));
const Cart = lazy(() => import("../components/Layouts/Cart/Cart"))
const MasterCardLandingPage = lazy(() => import('../components/Layouts/MasterCard/MasterCardLandingPage'));
const MyAccount = lazy(() => import('../components/Layouts/Account/MyAccount'));
const MyOrders = lazy(() => import('../components/Layouts/Account/MyOrders'));
const User = lazy(() => import('../components/Layouts/Account/User'));
const UserMobile = lazy(()=> import('../components/Layouts/Account/UserMobile'))
const PendingReviews = lazy(()=> import('../components/Layouts/Account/PendingReviews'))
const AddressBook = lazy(()=> import('../components/Layouts/Account/AddressBook'));
const Referrals = lazy(() => import("../components/Layouts/Account/Referrals"))
const Shop = lazy(() => import('../components/Layouts/ShopNow/Shop'))
const AllCloseProducts = lazy(() => import('../components/Layouts/ShopNow/AllCloseProducts'))
const AllProductsYouLike = lazy(() => import('../components/Layouts/ShopNow/AllProductsYouLike'))
const AllPopularNearYou = lazy(() => import('../components/Layouts/ShopNow/AllPopularNearYou'))

const AnimatedRoutes = () => {
    const location = useLocation()
  return (          
    <Routes location={location} key={location.pathname}>
        <Route path="/"  element={<Suspense fallback={<Loader />}><App /></Suspense>} exact/> 
        <Route path="/register" element={<Suspense fallback={<Loader />}><Register /></Suspense>} />
        <Route path="/login" element={<Suspense fallback={<Loader />}><Login /></Suspense>} />
        <Route path="/auth/forgot" element={<Suspense fallback={<Loader />}><ForgotPassword /></Suspense>} />
        <Route path="/auth/verify" element={<Suspense fallback={<Loader />}><VerifyOtp /></Suspense>} />
        <Route path="/auth/reset" element={<Suspense fallback={<Loader />}><ResetPassword /></Suspense>} />
        <Route path='/about' element={<Suspense fallback={<Loader />}><About /></Suspense>}/>
        <Route path="/mastercard" element={<Suspense fallback={<Loader />}><MasterCardLandingPage /></Suspense>}/>
        <Route path="/categories" element={<Suspense fallback={<Loader />}><Categories /></Suspense>}/>
        <Route path='/pricing' element={<Suspense fallback={<Loader />}><Pricing /></Suspense>}/>
        <Route path="/faq" element={<Suspense fallback={<Loader />}><Faq /></Suspense>} />
        <Route path="/contact" element={<Suspense fallback={<Loader />}><Contact /></Suspense>} />
        <Route path="/account/delete" element={<DeleteAccount />} />
        <Route path="/category/:categoryName" element={<Suspense fallback={<Loader />}><CategoryDetails /></Suspense>}/>
        <Route path="product/guest/:id" element={<Suspense fallback={<Loader />}><ProductDetails/></Suspense>}/>
        <Route path="product/alpha/:id" element={<Suspense fallback={<Loader />}><GiglProductDetails/></Suspense>}/>
        <Route path="stores/:slug" element={<Suspense fallback={<Loader />}><Store /></Suspense>} />
        <Route path="catalog" element={<Suspense fallback={<Loader />}><SearchCatalog /></Suspense>} />
        <Route path="stores/alpha/:slug" element={<Suspense fallback={<Loader />}><Giglsales /></Suspense>} />
        <Route path="order/tracking" element={<Suspense fallback={<Loader />}><TrackOrder /></Suspense>}/>
        <Route path="customer/order/track_shipment/:trackingId" element={<Suspense fallback={<Loader />}><TrackedItem /></Suspense>} />
        <Route path="apple-app-site-association" element={<Suspense fallback={<Loader />}><AppleAppSiteAssociation /></Suspense>} />
        <Route path="terms&conditions" element={<Suspense fallback={<Loader />}><Terms /></Suspense>}/>
        <Route path="privacy-policy" element={<Suspense fallback={<Loader />}><Policy /></Suspense>}/>
        <Route path="payment/refund" element={<Suspense fallback={<Loader />}><Refund /></Suspense>} />
        <Route path="payment/refund/requested" element={<Suspense fallback={<Loader />}><RefundRequested /></Suspense>} />
        <Route path="*" element={<Error customError="Page not found!" customErrorCode=""/>}/>
        <Route path="/cart" element={<Suspense fallback={<Loader />}><Cart /></Suspense>}/>
        <Route path="/success" element={<SuccessfulTransaction />} />
        <Route path="/promotions/:name" element={<Suspense fallback={<Loader />}><ListPromotions /></Suspense>} />
        <Route path='user' element={<Suspense fallback={<Loader />}><User/></Suspense>}>
          <Route index element={<MyAccount/>}/>
          <Route path='account' element={<Suspense fallback={<Loader />}><MyAccount/></Suspense>}/>
          <Route path='orders' element={<Suspense fallback={<Loader/>}><MyOrders/></Suspense>}/>
          <Route path='pending-reviews' element={<Suspense fallback={<Loader/>}><PendingReviews/></Suspense>}/>
          <Route path='address' element={<Suspense fallback={<Loader/>}><AddressBook/></Suspense>}/>
          <Route path='referrals' element={<Suspense fallback={<Loader/>}><Referrals /></Suspense>}/>
        </Route>
        <Route path='user/mobile' element={<Suspense fallback={<Loader/>}><UserMobile/></Suspense>}/>
        <Route path='user/account/mobile' element={<Suspense fallback={<Loader />}><MyAccount/></Suspense>}/>
        <Route path='user/orders/mobile' element={<Suspense fallback={<Loader/>}><MyOrders/></Suspense>}/>
        <Route path='user/pending-reviews/mobile' element={<Suspense fallback={<Loader/>}><PendingReviews/></Suspense>}/>
        <Route path='user/address/mobile' element={<Suspense fallback={<Loader/>}><AddressBook/></Suspense>}/>
        <Route path='user/referrals/mobile' element={<Suspense fallback={<Loader/>}><Referrals/></Suspense>}/>
        <Route path='merchant/store' element={<Suspense fallback={<Loader/>}><MerchantStore /></Suspense>}/>
        <Route path="merchant/store/upload" element={<Suspense fallback={<Loader />}><Upload /></Suspense>} />
        <Route path="merchant/store/upload/products" element={<Suspense fallback={<Loader />}><BulkProducts /></Suspense>}/>
        <Route path="merchant/store/upload/success" element={<Suspense fallback={<Loader />}><UploadSuccess /></Suspense>}/>

        <Route path='shop' element={<Suspense fallback={<Loader/>}><Shop/></Suspense>}/>
        <Route path='shop/close-to-you' element={<Suspense fallback={<Loader/>}><AllCloseProducts/></Suspense>}/>
        <Route path='shop/products-you-like' element={<Suspense fallback={<Loader/>}><AllProductsYouLike/></Suspense>}/>
        <Route path='shop/popular-near-you' element={<Suspense fallback={<Loader/>}><AllPopularNearYou/></Suspense>}/>
    </Routes>
  )
}

export default AnimatedRoutes